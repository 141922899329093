:root {
    --brand-1: #ff5000;
    --brand-2: #ff9000;
    --brand-3: #fff9eb;
    --black: #000;
    --white: #fff;
    --gray-1: #111;
    --gray-2: #666;
    --gray-3: #999;
    --gray-4: #ccc;
    --gray-5: #ddd;
    --gray-6: #eee;
    --gray-7: #f5f5f5;
    --gray-8: #f8f8f8;
    --light-1: #eee;
    --light-2: #f5f5f5;
    --highlight-1: var(--brand-1);
    --highlight-2: var(--brand-2);
    --link-color: var(--blue);
    --blue: #39f;
    --gray-dark: #333;
    --green: #62d957;
    --orange: #f70;
    --red: #ff3634;
    --yellow: #ffc233;
    --yellow-light: #fff9db;
    --font-size-xs: 0.75rem;
    --font-size-sm: 0.875rem;
    --font-size-md: 1rem;
    --font-size-lg: 1.125rem;
    --radius-sm: 0.25rem;
    --radius-md: 0.75rem;
    --radius-lg: 1.25rem;
    --shadow-1: 0 3px 4px 0 rgba(0, 0, 0, 0.04);
    --shadow-2: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
    --shadow-3: 0 6px 10px 0 rgba(0, 0, 0, 0.08);
    --safe-top: 0px;
    --safe-bottom: 0px;
    --gutter: 12px;
    --btn-primary-border-color: transparent;
    --btn-primary-bg: linear-gradient(
      90deg,
      var(--brand-2) 0%,
      var(--brand-1) 98%
    );
    --btn-primary-color: var(--white);
  }

  
  @supports (top: constant(safe-area-inset-top)) {
    :root {
      --safe-top: constant(safe-area-inset-top);
      --safe-bottom: constant(safe-area-inset-bottom);
    }
  }
  @supports (top: env(safe-area-inset-top)) {
    :root {
      --safe-top: env(safe-area-inset-top);
      --safe-bottom: env(safe-area-inset-bottom);
    }
  }
  .S--invisible {
    clip: rect(0, 0, 0, 0);
    position: absolute;
  }
  .pb-safe {
    padding-bottom: var(--safe-bottom);
  }
  @-webkit-keyframes slideInRight {
    0% {
      opacity: 0;
      -webkit-transform: translateX(6.25rem);
      transform: translateX(6.25rem);
    }
    to {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  @keyframes slideInRight {
    0% {
      opacity: 0;
      -webkit-transform: translateX(6.25rem);
      transform: translateX(6.25rem);
    }
    to {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  .slide-in-right-item {
    -webkit-animation: slideInRight 0.5s ease-in-out both;
    animation: slideInRight 0.5s ease-in-out both;
  }
  .slide-in-right-item:nth-child(2) {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }
  .slide-in-right-item:nth-child(3) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  .slide-in-right-item:nth-child(4) {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  .slide-in-right-item:nth-child(5) {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  .slide-in-right-item:nth-child(6) {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  .slide-in-right-item:nth-child(7) {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
  .slide-in-right-item:nth-child(8) {
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
  }
  .slide-in-right-item:nth-child(9) {
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
  }
  .slide-in-right-item:nth-child(10) {
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
  }
  .slide-in-right-item:nth-child(11) {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .A-fadeIn {
    -webkit-animation: fadeIn 0.6s;
    animation: fadeIn 0.6s;
  }
  .Avatar {
    border-radius: 50%;
    display: inline-block;
    overflow: hidden;
  }
  .Avatar img {
    display: block;
    height: 2.25rem;
    object-fit: cover;
    width: 2.25rem;
  }
  .Avatar--sm img {
    height: 1.5rem;
    width: 1.5rem;
  }
  .Avatar--lg img {
    height: 2.5rem;
    width: 2.5rem;
  }
  .Avatar--square {
    border-radius: 4px;
  }
  .Backdrop {
    background: rgba(0, 0, 0, 0.7);
    bottom: 0;
    height: 100vh;
    left: 0;
    opacity: 0;
    outline: 0;
    position: fixed;
    right: 0;
    transition: 0.3s;
    width: 100vw;
    z-index: 100;
  }
  .Backdrop.active {
    opacity: 1;
  }
  .Bubble {
    background: var(--white);
    border-radius: 12px;
    max-width: 42.5rem;
    min-width: 0.0625rem;
  }
  .Bubble.text,
  .Bubble.typing {
    word-wrap: break-word;
    box-sizing: border-box;
    min-width: 2.5rem;
    overflow-wrap: break-word;
    padding: 0.75rem;
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
    white-space: pre-wrap;
  }
  .Bubble.image img {
    border-radius: inherit;
    display: block;
    height: auto;
    max-height: 12.5rem;
    max-width: 12.5rem;
  }
  .Btn,
  .Bubble p {
    margin: 0;
  }
  .Btn {
    -webkit-tap-highlight-color: transparent;
    align-items: center;
    -webkit-appearance: none;
    background: var(--white);
    border: 1px solid var(--gray-5);
    border-radius: 999px;
    color: var(--gray-1);
    display: inline-flex;
    font-family: inherit;
    font-size: 0.875rem;
    font-weight: 400;
    justify-content: center;
    line-height: 1.5;
    min-width: 5rem;
    overflow: visible;
    padding: 0.3125rem 0.75rem;
    text-align: center;
    text-transform: none;
    transition: 0.15s ease-in-out;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
  }
  .Btn:not(:disabled) {
    cursor: pointer;
  }
  .Btn:focus:not(:focus-visible) {
    outline: 0;
  }
  .Btn:active {
    background: rgba(0, 0, 0, 0.08);
  }
  .Btn:disabled {
    color: var(--gray-4);
    opacity: 0.5;
    pointer-events: none;
  }
  .Btn--primary:not(.Btn--outline) {
    background: var(--btn-primary-bg);
    background-origin: border-box;
    border-color: var(--btn-primary-border-color);
    color: var(--btn-primary-color);
  }
  .Btn--primary:not(.Btn--outline):active {
    opacity: 0.8;
  }
  .Btn--outline.Btn--primary {
    border-color: var(--brand-1);
    color: var(--brand-1);
  }
  .Btn--sm {
    font-size: 0.875rem;
    padding: 0.25rem 0.75rem;
  }
  .Btn--lg {
    font-size: 1rem;
    padding: 0.4375rem 0.75rem;
  }
  .Btn--block {
    display: block;
    width: 100%;
  }
  .Btn-icon {
    -webkit-margin-end: 0.5rem;
    align-self: center;
    display: inline-flex;
    flex-shrink: 0;
    margin-inline-end: 0.5rem;
  }
  @media (hover: hover) {
    .Btn:hover {
      background: rgba(0, 0, 0, 0.04);
    }
    .Btn--primary:not(.Btn--outline):hover {
      background: var(--btn-primary-bg);
      opacity: 0.9;
    }
  }
  .Btn--text {
    border: 0;
    color: var(--link-color);
    font-size: inherit;
    padding: 0;
    vertical-align: initial;
  }
  .Btn--text,
  .Btn--text:active,
  .Btn--text:hover {
    background: transparent;
  }
  .BackBottom {
    bottom: 4.25rem;
    overflow: hidden;
    position: absolute;
    right: 0;
    z-index: 10;
  }
  .BackBottom .Btn {
    background: hsla(0, 0%, 100%, 0.85);
    border-radius: 50px 0 0 50px;
    border-right: 0;
    color: var(--brand-1);
    font-size: var(--font-size-sm);
  }
  .Card {
    background: var(--white);
    border-radius: 12px;
    overflow: hidden;
  }
  .Card--xl {
    width: 18.75rem;
  }
  .Card--lg {
    width: 10rem;
  }
  .Card--md {
    width: 7.5rem;
  }
  .Card--sm {
    width: 6.5rem;
  }
  .Card--xs {
    width: 5rem;
  }
  .Card--fluid {
    max-width: 42.5rem;
    min-width: 16.25rem;
    width: calc(100% - 3rem);
  }
  .Card[data-fluid="order"] {
    max-width: 22.5rem;
  }
  .CardMedia {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  .CardMedia:after {
    content: "";
    display: block;
    height: 0;
  }
  .CardMedia--wide:after {
    padding-top: 56.25%;
  }
  .CardMedia--square:after {
    padding-top: 100%;
  }
  .CardMedia-content {
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .CardTitle {
    padding: 0.75rem 0.75rem 0.375rem;
  }
  .CardTitle--center {
    padding: 0.25rem 0.125rem;
    text-align: center;
  }
  .CardTitle-title {
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
  }
  .CardTitle-subtitle {
    color: var(--gray-3);
    font-size: 0.625rem;
    margin: 0;
  }
  .CardContent {
    padding: 0.75rem;
  }
  .CardTitle + .CardContent {
    padding-top: 0;
  }
  .CardText {
    color: var(--gray-dark);
    padding: 0.75rem;
  }
  .CardTitle + .CardText {
    padding-top: 0;
  }
  .CardText p {
    margin: 0;
  }
  .CardActions {
    display: flex;
    padding: 0.75rem 0.75rem 1.125rem;
  }
  .CardContent + .CardActions,
  .CardText + .CardActions,
  .CardTitle + .CardActions {
    padding-top: 0;
  }
  .CardActions .Btn {
    flex: 1;
    line-height: 1.5;
  }
  .CardActions .Btn + .Btn {
    margin-left: 0.75rem;
  }
  .CardActions--column {
    flex-direction: column;
    padding: 0;
  }
  .CardActions--column .Btn {
    background: var(--white);
    border: 0;
    border-radius: 0;
    border-top: 1px solid var(--gray-6);
    color: var(--gray-3);
    padding: 0.625rem;
  }
  .CardActions--column .Btn:last-child {
    border-radius: 0 0 12px 12px;
  }
  .CardActions--column .Btn:active {
    background: var(--gray-7);
  }
  .CardActions--column .Btn:disabled {
    color: var(--gray-4);
  }
  .CardActions--column .Btn + .Btn {
    margin: 0;
  }
  .CardActions--column .Btn--primary {
    color: var(--brand-1);
  }
  @media (hover: hover) {
    .CardActions--column .Btn:hover {
      background: var(--gray-7);
    }
  }
  .Divider {
    align-items: center;
    color: var(--gray-3);
    display: flex;
    font-size: 0.75rem;
    margin: 0.75rem 0;
  }
  .Divider:after,
  .Divider:before {
    border-top: 1px solid var(--gray-5);
    content: "";
    display: block;
    flex: 1;
  }
  .Divider--text-center:before,
  .Divider--text-left:before,
  .Divider--text-right:before {
    margin-right: var(--gutter);
  }
  .Divider--text-center:after,
  .Divider--text-left:after,
  .Divider--text-right:after {
    margin-left: var(--gutter);
  }
  .Divider--text-left:before,
  .Divider--text-right:after {
    max-width: 10%;
  }
  .Empty {
    padding: 1.875rem;
    text-align: center;
  }
  .Empty-img {
    height: 7.8125rem;
  }
  .Empty-tip {
    color: var(--gray-4);
    margin: 1.25rem 0;
  }
  .Flex {
    display: flex;
  }
  .Flex--inline {
    display: inline-flex;
  }
  .Flex--center {
    align-items: center;
    justify-content: center;
  }
  .Flex--d-r {
    flex-direction: row;
  }
  .Flex--d-rr {
    flex-direction: row-reverse;
  }
  .Flex--d-c {
    flex-direction: column;
  }
  .Flex--d-cr {
    flex-direction: column-reverse;
  }
  .Flex--w-n {
    flex-wrap: nowrap;
  }
  .Flex--w-w {
    flex-wrap: wrap;
  }
  .Flex--w-wr {
    flex-wrap: wrap-reverse;
  }
  .Flex--jc-fs {
    justify-content: flex-start;
  }
  .Flex--jc-fe {
    justify-content: flex-end;
  }
  .Flex--jc-c {
    justify-content: center;
  }
  .Flex--jc-sb {
    justify-content: space-between;
  }
  .Flex--jc-sa {
    justify-content: space-around;
  }

  .Flex--ai-fs {
    align-items: flex-start;
  }
  .Flex--ai-fe {
    align-items: flex-end;
  }
  .Flex--ai-c {
    align-items: center;
  }
  .FlexItem {
    flex: 1;
    min-height: 0;
    min-width: 0;
  }
  .HelpText {
    color: var(--gray-4);
    font-size: 0.75rem;
  }
  .Icon {
    stroke-width: 0;
    fill: currentColor;
    display: inline-block;
    height: 1em;
    transition: all 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    width: 1em;
  }
  .is-spin {
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn);
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn);
    }
  }
  .IconBtn {
    background: transparent;
    border: 0;
    border-radius: 3px;
    color: var(--gray-2);
    font-size: 1.125rem;
    min-width: 0;
    padding: 0;
  }
  .IconBtn.Btn--primary {
    color: var(--brand-2);
  }
  .IconBtn:disabled {
    border-color: var(--gray-6);
    color: var(--gray-6);
  }
  .IconBtn.Btn--lg {
    border-radius: 6px;
    font-size: 1.5rem;
  }
  .IconBtn > .Icon,
  .IconBtn > img {
    display: block;
  }
  .IconBtn > img {
    height: 1em;
    width: 1em;
  }
  .Image {
    border-style: none;
    display: inline-block;
    overflow: hidden;
    position: relative;
  }
  .Image--fluid {
    height: auto;
    max-width: 100%;
  }
  .InfiniteScroll {
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
  }
  .InputWrapper {
    position: relative;
  }
  .InputWrapper.has-counter {
    padding-bottom: 1.25rem;
  }
  .InputWrapper.has-counter + .HelpText {
    margin-top: -1.25rem;
  }
  .Input {
    -webkit-tap-highlight-color: transparent;
    -webkit-appearance: none;
    background: var(--white);
    border: 1px solid var(--gray-6);
    border-radius: 12px;
    box-sizing: border-box;
    color: var(--gray-1);
    display: block;
    font-family: inherit;
    font-size: 0.875rem;
    line-height: 1.5;
    margin: 0;
    min-height: 1.5rem;
    padding: 0.3125rem 0.75rem;
    resize: none;
    transition: 0.2s ease-in-out;
    width: 100%;
  }
  .Input:focus {
    border-color: var(--brand-1);
    outline: none;
  }
  .Input:focus:not([disabled]):not([readonly]) ~ .Input-line:after,
  .Input:focus:not([disabled]):not([readonly]) ~ .Input-line:before {
    width: 50%;
  }
  .Input::-webkit-input-placeholder {
    color: var(--gray-4);
  }
  .Input:-ms-input-placeholder {
    color: var(--gray-4);
  }
  .Input::placeholder {
    color: var(--gray-4);
  }
  .Input--filled {
    background-color: var(--gray-6);
    border-color: transparent;
  }
  .Input--flushed {
    background: none;
    border-radius: 0;
    border-width: 0 0 1px;
    padding: 0.125rem var(--gutter);
  }
  .Input--flushed:focus {
    box-shadow: var(--brand-1) 0 1px 0 0;
  }
  .Input-counter {
    color: var(--gray-3);
    float: right;
    font-size: 0.75rem;
    margin-right: var(--gutter);
    position: relative;
    z-index: 1;
  }
  .Label {
    color: var(--gray-2);
    display: block;
    font-size: 0.75rem;
  }
  .List {
    background: var(--white);
  }
  .List--bordered {
    border: 1px solid var(--gray-7);
    border-radius: 2px;
  }
  .ListItem {
    align-items: center;
    border: 0;
    box-sizing: border-box;
    color: var(--gray-1);
    display: flex;
    font-size: 0.9375rem;
    line-height: 1.6;
    padding: 0.625rem var(--gutter);
    text-decoration: none;
    transition: 0.3s;
  }
  .ListItem:focus:not(:focus-visible) {
    outline: 0;
  }
  .ListItem + .ListItem {
    border-top: 1px solid var(--gray-7);
  }
  .ListItem .Icon {
    color: var(--gray-3);
  }
  button.ListItem {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    text-align: left;
    width: 100%;
  }
  a.ListItem:active,
  button.ListItem:active {
    background: var(--gray-6);
  }
  @media (hover: hover) {
    a.ListItem:hover,
    button.ListItem:hover {
      background: rgba(0, 0, 0, 0.04);
      background-clip: padding-box;
      cursor: pointer;
    }
  }
  .ListItem-content {
    flex: 1;
  }
  .Loading {
    color: var(--gray-2);
    padding: 0.75rem;
  }
  .Loading .Icon {
    font-size: 1.875rem;
  }
  .Loading-tip {
    font-size: 0.875rem;
    margin: 0 0 0 0.375rem;
  }
  .MediaObject {
    display: flex;
  }
  .MediaObject-pic {
    margin-right: 0.625rem;
    width: 4.375rem;
  }
  .MediaObject-pic > img {
    display: block;
    height: 100%;
    width: 100%;
  }
  .MediaObject-info {
    flex: 1;
  }
  .MediaObject-title {
    font-size: 0.875rem;
    font-weight: 400;
    margin: 0 0 0.375rem;
  }
  .MediaObject-meta {
    color: var(--gray-2);
    font-size: 0.75rem;
  }
  .Message {
    position: relative;
  }
  .Message + .Message {
    margin-top: 0.75rem;
  }
  .Message.left .Message-main > .Avatar {
    margin-right: 0.25rem;
  }
  .Message.left .Bubble {
    margin-right: 3rem;
  }
  .Message.right .Message-content,
  .Message.right .Message-main {
    flex-direction: row-reverse;
  }
  .Message.right .Message-main > .Avatar {
    margin-left: 0.25rem;
  }
  .Message.right .Message-author {
    text-align: right;
  }
  .Message.right .Bubble {
    background: var(--brand-3);
    border-radius: 12px;
    margin-left: 2.5rem;
  }
  .Message.pop {
    display: none;
  }
  .Message-meta {
    display: flex;
    justify-content: center;
    margin-bottom: 0.75rem;
    text-align: center;
  }
  .Message-content,
  .Message-main {
    align-items: flex-start;
    display: flex;
  }
  .Message-inner {
    flex: 1;
    min-width: 0;
  }
  .Message-author {
    color: var(--gray-2);
    font-size: var(--font-size-xs);
    line-height: 1.1;
    margin-bottom: 0.375rem;
  }
  .SystemMessage {
    color: var(--gray-2);
    font-size: 0.75rem;
    padding: 0 0.9375rem;
    text-align: center;
  }
  .SystemMessage a {
    margin-left: 0.3125rem;
  }
  .SystemMessage-inner {
    background: var(--gray-8);
    border-radius: 6px;
    display: inline-block;
    padding: 0.375rem 0.5625rem;
    text-align: left;
  }
  .MessageStatus {
    align-self: center;
    margin-right: 0.75rem;
  }
  .MessageStatus[data-status="loading"] .Icon {
    color: var(--gray-4);
  }
  .MessageStatus[data-status="fail"] .IconBtn {
    color: #ff5959;
  }
  .MessageStatus .Icon,
  .MessageStatus .IconBtn {
    display: block;
  }
  .Message.right .Bubble + .MessageStatus {
    margin-right: -1.75rem;
  }
  .Modal,
  .Popup {
    align-items: center;
    bottom: 0;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    outline: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
  }
  .Modal.active .Modal-dialog {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
  .Modal-dialog {
    background: var(--white);
    border-radius: 12px;
    font-size: 0.9375rem;
    opacity: 0;
    overflow: hidden;
    position: relative;
    -webkit-transform: translateY(-3.125rem);
    transform: translateY(-3.125rem);
    transition: opacity 0.15s linear, -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out, opacity 0.15s linear;
    transition: transform 0.3s ease-out, opacity 0.15s linear,
      -webkit-transform 0.3s ease-out;
    width: 20rem;
    z-index: 100;
  }
  @media (max-width: 320px) {
    .Modal-dialog {
      width: 18.75rem;
    }
  }
  .Modal-header {
    padding: 1.125rem 1.25rem 0.625rem;
    position: relative;
  }
  .Modal-body {
    padding: 1.125rem 0.9375rem;
  }
  .Modal-header + .Modal-body {
    padding-top: 0;
  }
  .Modal-footer {
    display: flex;
  }
  .Modal-footer .Btn--outline {
    border-color: var(--gray-6);
    border-radius: 0;
    border-width: 1px 0 0;
    padding-bottom: 0.625rem;
    padding-top: 0.625rem;
  }
  .Modal-footer .Btn--outline:not(.Btn--primary) {
    color: var(--gray-2);
  }
  .Modal-footer--h[data-variant="round"] {
    padding: 0 0.9375rem 1.125rem;
  }
  .Modal-footer--h[data-variant="round"] .Btn + .Btn {
    margin-left: 0.75rem;
  }
  .Modal-footer--h[data-variant="outline"] .Btn + .Btn {
    border-left-width: 1px;
  }
  .Modal-footer--h .Btn {
    flex: 1;
  }
  .Modal-footer--v {
    flex-direction: column;
  }
  .Confirm .Modal-body {
    text-align: center;
  }
  .Popup {
    align-items: flex-end;
  }
  .Popup.active .Popup-dialog {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  .Popup-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .Popup-dialog {
    background: var(--white);
    border-radius: 27px 27px 0 0;
    opacity: 0;
    overflow: hidden;
    position: relative;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    transition: 0.3s;
    width: 100%;
    z-index: 100;
  }
  .Popup-dialog[data-bg-color="gray"] {
    background: var(--gray-7);
  }
  .Popup-dialog[data-height="80"] {
    height: 80vh;
  }
  .Popup-dialog[data-height="60"] {
    height: 60vh;
  }
  .Popup-dialog[data-height="40"] {
    height: 40vh;
  }
  .Popup-header {
    margin-top: 0.25rem;
    padding: 0.9375rem 2.5rem;
    position: relative;
  }
  .Modal-title,
  .Popup-title {
    color: var(--gray-1);
    font-size: 1.125rem;
    font-weight: 500;
    margin: 0;
    min-height: 1.5625rem;
    text-align: center;
  }
  .Modal-close,
  .Popup-close {
    color: var(--gray-1);
    position: absolute;
    right: 0.75rem;
  }
  .Modal-close {
    top: 0.75rem;
  }
  .Popup-close {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .Popup-body {
    flex: 1;
    min-height: 0;
  }
  .Popup-body.overflow {
    -webkit-overflow-scrolling: touch;
    max-height: 70vh;
    overflow-y: auto;
  }
  .Popup-footer {
    background: var(--white);
    display: flex;
    flex-direction: column;
    padding: 0.5625rem 0.75rem;
    padding-bottom: calc(0.5625rem + var(--safe-bottom));
    position: relative;
  }
  .Popup-footer .Btn + .Btn {
    margin-top: 0.5625rem;
  }
  .S--modalOpen,
  .S--modalOpen .MessageContainer > .PullToRefresh {
    overflow: hidden;
  }
  .S--wide .Popup {
    align-items: center;
  }
  .S--wide .Popup-dialog {
    border-radius: 12px;
    width: 30rem;
  }
  .S--wide .Popup-footer--v {
    padding-bottom: 0.75rem;
  }
  .Navbar {
    background: var(--gray-6);
    border-bottom: 1px solid var(--gray-5);
    padding: 0 var(--gutter);
    padding-top: var(--safe-top);
    position: relative;
    z-index: 10;
  }
  .Navbar,
  .Navbar-main {
    align-items: center;
    display: flex;
  }
  .Navbar-main {
    flex: 1;
    justify-content: center;
    min-height: 2.75rem;
  }
  .Navbar-left,
  .Navbar-right {
    width: 3.625rem;
  }
  .Navbar-right {
    text-align: right;
    white-space: nowrap;
  }
  .Navbar-title {
    color: var(--gray-1);
    font-size: 1.125rem;
    font-weight: 500;
    margin: 0;
    text-align: center;
  }
  .Navbar-logo {
    height: 2.25rem;
    width: auto;
  }
  .Navbar .IconBtn {
    color: var(--gray-1);
  }
  .Navbar .IconBtn + .IconBtn {
    margin-left: 0.5625rem;
  }
  .Notice {
    align-items: center;
    background: var(--white);
    border-radius: 12px;
    display: flex;
    padding: 0.75rem;
  }
  .Notice-icon {
    margin-right: 0.375rem;
  }
  .Notice-close {
    margin-left: 0.375rem;
  }
  .Notice .Icon {
    color: var(--brand-1);
    font-size: 1rem;
  }
  .Notice-content {
    color: var(--gray-1);
    flex: 1;
    font-size: 0.8125rem;
    min-width: 0;
  }
  .Popover {
    font-size: 0.875rem;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transform: translateY(-0.625rem);
    transform: translateY(-0.625rem);
    z-index: 1030;
  }
  .Popover-body {
    background: var(--white);
    border-radius: 6px;
    box-shadow: var(--shadow-3);
  }
  .Popover-arrow {
    fill: var(--white);
    display: block;
    height: 0.3125rem;
    margin-left: 0.625rem;
    width: 0.5625rem;
  }
  .Price {
    display: inline;
    font-size: 0.875rem;
    font-weight: 500;
  }
  .Price--original {
    color: var(--gray-3);
    text-decoration: line-through;
  }
  .Price .Price-fraction {
    font-size: 0.857em;
  }
  .Progress {
    background-color: var(--gray-5);
    border-radius: 100px;
    display: flex;
    height: 0.125rem;
    overflow: hidden;
  }
  .Progress-bar {
    background-color: var(--blue);
    overflow: hidden;
    transition: width 0.6s ease;
  }
  .Progress--success .Progress-bar {
    background-color: var(--green);
  }
  .Progress--error .Progress-bar {
    background-color: var(--red);
  }
  .PullToRefresh {
    -webkit-overflow-scrolling: touch;
    height: 100%;
    overflow-y: scroll;
  }
  .PullToRefresh-fallback {
    padding-top: var(--gutter);
    text-align: center;
  }
  .PullToRefresh-loadMore {
    font-size: 0.875rem;
  }
  .PullToRefresh-inner {
    min-height: 100%;
    overflow: hidden;
  }
  .PullToRefresh-indicator {
    color: grey;
    height: 1.875rem;
    line-height: 1.875rem;
    margin-top: -1.875rem;
    text-align: center;
  }
  .PullToRefresh-spinner {
    color: var(--gray-3);
    font-size: 1.6875rem;
  }
  .PullToRefresh-transition {
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
  }
  .QuickReplies {
    background: var(--gray-7);
    bottom: 100%;
    left: 0;
    overflow: hidden;
    padding: 0.5625rem var(--gutter);
    position: absolute;
    right: 0;
    transition: opacity 0.3s;
    z-index: 110;
  }
  .QuickReplies[data-visible="false"] {
    opacity: 0;
    visibility: hidden;
  }
  .QuickReplies:not(.ScrollView--hasControls) {
    padding-left: 0;
    padding-right: 0;
  }
  .QuickReplies:not(.ScrollView--hasControls) .ScrollView-inner {
    padding: 0 var(--gutter);
  }
  .QuickReplies:not(.ScrollView--hasControls) .ScrollView-item:last-child {
    padding-right: var(--gutter);
  }
  .QuickReply {
    background: var(--white);
    border: 1px solid transparent;
    border-radius: 15px;
    color: var(--gray-1);
    cursor: pointer;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin: 0;
    padding: 0.25rem 0.75rem;
    position: relative;
    transition: 0.15s ease-in-out;
  }
  .QuickReply.new:after {
    background: var(--red);
    border: 1px solid #fff;
    border-radius: 50%;
    content: "";
    height: 0.5rem;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    width: 0.5rem;
  }
  .QuickReply.highlight {
    background: #fff;
    border-color: #ffd0bf;
    font-weight: 500;
  }
  .QuickReply-inner {
    align-items: center;
    display: flex;
  }
  .QuickReply-img {
    max-height: 1rem;
  }
  .QuickReply-img,
  .QuickReply-inner > .Icon {
    margin-right: 0.1875rem;
  }
  .QuickReply .Icon {
    color: var(--brand-1);
    font-size: 0.9375rem;
  }
  .Checkbox,
  .Radio {
    -webkit-tap-highlight-color: transparent;
    background: var(--white);
    border: 1px solid var(--gray-6);
    border-radius: 9px;
    color: var(--gray-2);
    cursor: pointer;
    display: inline-block;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin: 0.5625rem 0.75rem 0 0;
    padding: 0.25rem 0.75rem;
    position: relative;
    text-align: center;
    transition: 0.15s ease-in-out;
  }
  .RadioGroup {
    margin-top: -0.5625rem;
  }
  .RadioGroup--block .Radio {
    display: block;
    margin-right: 0.5625rem;
  }
  .CheckboxGroup--block .Checkbox {
    display: block;
    margin-right: 0;
  }
  .Checkbox--disabled,
  .Radio--disabled {
    cursor: auto;
    opacity: 0.5;
  }
  .Checkbox--checked,
  .Radio--checked {
    border-color: var(--brand-1);
    color: var(--brand-1);
  }
  .Checkbox-input,
  .Radio-input {
    cursor: inherit;
    height: 100%;
    left: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .RateActions {
    align-self: flex-end;
    margin: 0 0.5rem;
    position: relative;
    width: 2rem;
    z-index: 10;
  }
  .RateBtn {
    background: var(--white);
    border-radius: 50%;
    font-size: 1.25rem;
    padding: 0.375rem;
  }
  .RateBtn + .RateBtn {
    margin-top: 0.5625rem;
  }
  .RateBtn[data-type="up"].active,
  .RateBtn[data-type="up"]:hover {
    color: var(--brand-1);
  }
  .RateBtn[data-type="down"].active,
  .RateBtn[data-type="down"]:hover {
    color: var(--blue);
  }
  .RichText {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  .ScrollView {
    overflow: hidden;
  }
  .ScrollView-scroller {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    scrollbar-width: none;
  }
  .ScrollView-scroller::-webkit-scrollbar {
    display: none;
  }
  .ScrollView--fullWidth {
    margin: 0 calc(var(--gutter) * -1);
  }
  .ScrollView--fullWidth:not(.ScrollView--hasControls) .ScrollView-inner {
    padding: 0 var(--gutter);
  }
  .ScrollView--x .ScrollView-scroller {
    display: flex;
    margin-bottom: -1.125rem;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 1.125rem;
  }
  .ScrollView--x .ScrollView-inner {
    display: flex;
  }
  .ScrollView--x .ScrollView-item {
    flex: 0 0 auto;
    margin-left: 0.375rem;
  }
  .ScrollView--x .ScrollView-item:first-child {
    margin-left: 0;
  }
  .ScrollView--hasControls {
    align-items: center;
    display: flex;
  }
  .ScrollView--hasControls .ScrollView-scroller {
    flex: 1;
  }
  .ScrollView-control {
    color: var(--gray-3);
    font-size: 1rem;
    padding: 0.375rem;
  }
  .ScrollView-control:not(:disabled):hover {
    color: var(--brand-1);
  }
  .Search {
    align-items: center;
    background: var(--white);
    border-radius: 50px;
    display: flex;
    padding: 0.1875rem 0.3125rem 0.1875rem 0.9375rem;
  }
  .Search-clear,
  .Search-icon {
    font-size: var(--font-size-lg);
  }
  .Search-icon {
    color: var(--gray-3);
  }
  .Search-input {
    border: 0;
    flex: 1;
    padding: 0 0.5625rem;
  }
  .Search-input::-webkit-search-cancel-button {
    display: none;
  }
  .Search-input:focus + .Search-clear,
  .Search-input:focus ~ .Btn--primary {
    opacity: 1;
  }
  .Search-clear {
    color: var(--gray-5);
    opacity: 0;
  }
  .Search-clear:hover {
    background: initial;
    color: var(--gray-3);
  }
  .Search .Btn--primary {
    font-size: var(--font-size-xs);
    margin-left: 0.375rem;
    min-width: 3.5rem;
    opacity: 0;
    padding: 0.125rem 0.75rem;
  }
  .Select {
    -webkit-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E");
    background-position: right 0.75rem center;
    background-repeat: no-repeat;
    background-size: 1rem 0.75rem;
  }
  .Select:disabled {
    background-color: var(--gray-7);
  }
  .Stepper {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .Step {
    padding-bottom: 1.125rem;
    padding-left: 1.8125rem;
    position: relative;
  }
  .Step:last-child {
    padding-bottom: 0;
  }
  .Step:last-child .Step-line {
    display: none;
  }
  .Step--active .Step-dot {
    background: var(--brand-1);
  }
  .Step--active .Step-title span {
    color: var(--brand-1);
    font-weight: 500;
  }
  .Step--active .Step-desc {
    color: var(--gray-1);
  }
  .Step--active[data-status] .Step-line {
    top: 1.625rem;
  }
  .Step--active[data-status] .Step-icon {
    color: var(--red);
  }
  .Step--active[data-status="success"] .Step-icon {
    color: var(--green);
  }
  .Step-icon {
    align-items: center;
    display: flex;
    font-size: 1.5rem;
    height: 1.5rem;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 1.5rem;
  }
  .Step-dot {
    background: var(--white);
    border: 1px solid var(--brand-1);
    border-radius: 50%;
    height: 0.5rem;
    width: 0.5rem;
  }
  .Step-line {
    background: var(--brand-1);
    bottom: -0.25rem;
    left: 0.75rem;
    opacity: 0.5;
    position: absolute;
    top: 1.25rem;
    width: 0.0625rem;
  }
  .Step-title {
    align-items: center;
    color: var(--gray-1);
    display: flex;
    font-size: var(--font-size-md);
  }
  .Step-title small {
    color: var(--gray-3);
    font-size: var(--font-size-xs);
    margin-left: 0.75rem;
  }
  .Step-desc {
    color: var(--gray-3);
    font-size: var(--font-size-sm);
    margin-top: 0.1875rem;
  }
  .Tabs-nav {
    display: flex;
    margin: 0.4375rem 0;
    position: relative;
  }
  .Tabs-nav::-webkit-scrollbar {
    display: none;
  }
  .Tabs--scrollable .Tabs-nav {
    -webkit-overflow-scrolling: touch;
    margin-bottom: -0.6875rem;
    overflow: hidden;
    overflow-x: auto;
    padding-bottom: 1.125rem;
  }
  .Tabs--scrollable .Tabs-navPointer {
    bottom: 1.125rem;
  }
  .Tabs--scrollable .Tabs-navItem {
    flex: 1 0 auto;
  }
  .Tabs-navItem {
    flex: 1;
    text-align: center;
  }
  .Tabs-navLink {
    background: transparent;
    border: 0;
    border-radius: 20px;
    color: var(--gray-2);
    font-size: 0.875rem;
    padding: 0.25rem 0.75rem;
    transition: 0.3s;
  }
  .Tabs-navLink:focus:not(:focus-visible) {
    outline: 0;
  }
  .Tabs-navLink:hover {
    color: var(--gray-1);
    cursor: pointer;
  }

  .Tabs-navLink.active {
    color: var(--gray-1);
    font-weight: 700;
    position: relative;
    z-index: 1;
  }
  .Tabs-navPointer {
    background: var(--btn-primary-bg);
    border-radius: 2px;
    bottom: 0;
    height: 0.1875rem;
    left: 0;
    position: absolute;
    transition: 0.3s;
  }
  .Tabs-pane {
    display: none;
  }
  .Tabs-pane.active {
    display: block;
  }
  .Tag {
    border: 1px solid var(--brand-1);
    border-radius: 4px;
    color: var(--brand-1);
    display: inline-block;
    font-size: 0.75rem;
    line-height: 1.25;
    margin: 0 0.25rem 0 0;
    padding: 0 0.375rem;
    position: relative;
    white-space: nowrap;
  }
  .Tag--primary {
    border-color: transparent;
    color: var(--orange);
  }
  .Tag--primary:before {
    background: currentColor;
    border-radius: inherit;
    bottom: 0;
    content: "";
    left: 0;
    margin: -0.0625rem;
    opacity: 0.14;
    position: absolute;
    right: 0;
    top: 0;
  }
  .Tag--success {
    background: var(--green);
    border-color: var(--green);
    color: #fff;
  }
  .Tag--danger {
    background: var(--red);
    border-color: var(--red);
    color: #fff;
  }
  .Tag--warning {
    background: var(--orange);
    border-color: var(--orange);
    color: #fff;
  }
  .Text--truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .Text--break {
    overflow-wrap: break-word !important;
    word-break: break-word !important;
  }
  .Text--ellipsis {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .Time {
    color: var(--gray-3);
    font-size: 0.75rem;
  }
  .Toast {
    display: flex;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 30%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all 0.3s ease 0s;
    visibility: hidden;
    z-index: 200;
  }
  .Toast[data-type="success"] .Icon {
    color: var(--green);
  }
  .Toast[data-type="error"] .Icon {
    color: var(--red);
  }
  .Toast[data-type="loading"] .Icon {
    color: var(--brand-1);
  }
  .Toast.show {
    opacity: 1;
    visibility: visible;
  }
  .Toast .Icon {
    font-size: 1.5rem;
    margin-right: 0.375rem;
  }
  .Toast-content {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 12px;
    box-sizing: border-box;
    display: flex;
    max-width: 18.75rem;
    padding: 1.125rem 1.5rem;
  }
  .Toast-message {
    color: var(--white);
    flex: 1;
    font-size: 1rem;
    margin: 0;
    word-break: break-word;
  }
  .Toolbar {
    padding: var(--gutter);
    padding-top: 0;
  }
  .Toolbar-item {
    display: inline-block;
    margin-top: var(--gutter);
    text-align: center;
    width: 25%;
  }
  .Toolbar-btn {
    border: 0;
    color: var(--gray-2);
    display: inline-block;
    overflow: hidden;
    padding: 0.375rem;
    position: relative;
    vertical-align: top;
    width: 4.75rem;
  }
  .Toolbar-btn,
  .Toolbar-btn:hover {
    background: transparent;
  }
  .Toolbar-btn:active .Toolbar-btnIcon {
    background: rgba(0, 0, 0, 0.04);
  }
  @media (hover: hover) {
    .Toolbar-btn:hover .Toolbar-btnIcon {
      background: rgba(0, 0, 0, 0.04);
    }
  }
  .Toolbar-btnIcon {
    background: var(--white);
    border-radius: 12px;
    display: inline-block;
    padding: 0.75rem;
    transition: 0.3s;
  }
  .Toolbar-btnIcon .Icon {
    font-size: 1.875rem;
    vertical-align: top;
  }
  .Toolbar-img {
    height: 1.875rem;
    vertical-align: top;
    width: 1.875rem;
  }
  .Toolbar-btnText {
    display: block;
    font-size: 0.875rem;
    margin-top: 0.375rem;
  }
  [data-tooltip] {
    cursor: pointer;
    position: relative;
  }
  [data-tooltip]:after,
  [data-tooltip]:before {
    bottom: 100%;
    left: 50%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    -webkit-transform: translate(-50%, 0.25rem);
    transform: translate(-50%, 0.25rem);
    -webkit-transform-origin: top;
    transform-origin: top;
    transition: all 0.18s ease-out 0.18s;
    z-index: 200;
  }
  [data-tooltip]:after {
    background: var(--gray-1);
    border-radius: 6px;
    color: var(--white);
    content: attr(aria-label);
    font-size: 0.75rem;
    margin-bottom: 0.625rem;
    padding: 0.5em 1em;
    white-space: nowrap;
  }
  [data-tooltip]:before {
    border: 0.3125rem solid transparent;
    border-top: 0.3125rem solid var(--gray-1);
    content: "";
    height: 0;
    -webkit-transform-origin: top;
    transform-origin: top;
    width: 0;
  }
  [data-tooltip]:hover:after,
  [data-tooltip]:hover:before {
    opacity: 1;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
  }
  .Tree {
    background: var(--white);
  }
  .TreeNode-title {
    align-items: center;
    border-bottom: 1px solid var(--gray-7);
    display: flex;
    justify-content: space-between;
    padding: 0.625rem 0.9375rem;
  }
  .TreeNode-title:hover {
    background: var(--gray-7);
    color: var(--brand-1);
    cursor: pointer;
  }
  .TreeNode:last-child .TreeNode-title {
    border: 0;
  }
  .TreeNode-children-title {
    background: var(--gray-7);
    border-bottom: 1px solid var(--gray-7);
  }
  .TreeNode-title-text {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .TreeNode-children {
    display: none;
  }
  .TreeNode-children-active {
    display: block;
  }
  .Typing {
    align-items: center;
    display: flex;
    height: 1.40625rem;
    transition: opacity 0.1s;
  }
  .Typing-dot {
    -webkit-animation: typing-dot 0.8s linear infinite;
    animation: typing-dot 0.8s linear infinite;
    background: var(--gray-3);
    border-radius: 50%;
    display: inline-block;
    height: 0.3125rem;
    margin-left: 0.5625rem;
    width: 0.3125rem;
  }
  .Typing-dot:first-child {
    margin: 0;
  }
  .Typing-dot:nth-child(2) {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  }
  .Typing-dot:nth-child(3) {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }
  @-webkit-keyframes typing-dot {
    0% {
      -webkit-transform: scale(0.57);
      transform: scale(0.57);
    }
    25% {
      -webkit-transform: scale(0.78);
      transform: scale(0.78);
    }
    50% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    75% {
      -webkit-transform: scale(0.78);
      transform: scale(0.78);
    }
    to {
      -webkit-transform: scale(0.57);
      transform: scale(0.57);
    }
  }
  @keyframes typing-dot {
    0% {
      -webkit-transform: scale(0.57);
      transform: scale(0.57);
    }
    25% {
      -webkit-transform: scale(0.78);
      transform: scale(0.78);
    }
    50% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    75% {
      -webkit-transform: scale(0.78);
      transform: scale(0.78);
    }
    to {
      -webkit-transform: scale(0.57);
      transform: scale(0.57);
    }
  }
  .Video {
    border-radius: inherit;
    position: relative;
  }
  .Video-cover,
  .Video-video:not([hidden]) {
    border-radius: inherit;
    display: block;
    max-height: 100%;
    width: 100%;
  }
  .Video-duration {
    bottom: 0.3125rem;
    color: var(--white);
    position: absolute;
    right: 0.3125rem;
    z-index: 1;
  }
  .Video-duration:after {
    content: "＂";
  }
  .Video-playBtn {
    background: transparent;
    border: 0;
    height: 100%;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .Video-playBtn:hover {
    cursor: pointer;
  }
  .Video-playIcon {
    background: url(//gw.alicdn.com/tfs/TB1p1mkqIbpK1RjSZFyXXX_qFXa-70-70.png) 0 0
      no-repeat;
    background-size: cover;
    display: inline-block;
    height: 2.1875rem;
    width: 2.1875rem;
  }
  .Video--playing .Video-playBtn {
    display: none;
  }
  .Carousel {
    overflow: hidden;
    position: relative;
  }
  .Carousel--draggable .Carousel-inner {
    cursor: grab;
    touch-action: pan-y;
  }
  .Carousel--draggable .Carousel-inner:active {
    cursor: grabbing;
  }
  .Carousel--rtl {
    direction: rtl;
  }
  .Carousel--dragging .Carousel-item {
    pointer-events: none;
  }
  .Carousel-inner {
    display: flex;
    will-change: transform;
  }
  .Carousel-dots {
    bottom: 0.5rem;
    display: flex;
    justify-content: center;
    left: 50%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 1;
  }
  .Carousel-dot {
    background: var(--gray-4);
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    display: block;
    height: 0.5rem;
    margin: 0 0.25rem;
    padding: 0;
    transition: 0.3s;
    width: 0.5rem;
  }
  .Carousel-dot.active {
    background: var(--brand-1);
  }
  .Goods {
    font-size: 0.875rem;
    padding: 0.75rem;
  }
  .Goods + .Goods {
    border-top: 1px solid var(--gray-7);
  }
  .Goods-img {
    border-radius: 12px;
    height: 4.5rem;
    margin-right: 0.5625rem;
    object-fit: cover;
    width: 4.5rem;
  }
  .Goods-name {
    font-size: 0.875rem;
    font-weight: 400;
    margin: 0 0 0.1875rem;
  }
  .Goods-main .Price {
    margin-right: 0.5625rem;
  }
  .Goods-desc {
    color: var(--gray-3);
    margin-bottom: 0.1875rem;
    word-break: break-all;
  }
  .Goods-meta {
    color: var(--gray-3);
    font-size: 0.625rem;
  }
  .Goods-countUnit {
    color: var(--gray-3);
    font-size: 0.75rem;
    margin: 0.1875rem 0;
  }
  .Goods-unit {
    font-size: 0.625rem;
    margin-left: 0.1875rem;
  }
  .Goods-buyBtn {
    color: #fff;
    float: right;
    padding: 0.125rem;
  }
  .Goods-buyBtn,
  .Goods-buyBtn:hover {
    background: var(--brand-1);
  }
  .Goods-detailBtn {
    border-radius: 10px;
    font-size: 0.625rem;
    line-height: 1.125rem;
    min-width: 3rem;
    padding: 0 0.625rem;
  }
  .Goods-aside {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    margin-left: 0.5625rem;
  }
  .Goods-status {
    color: var(--highlight-2);
    font-size: 0.75rem;
  }
  .FileCard {
    padding: 0.5rem;
  }
  .FileCard-icon {
    color: var(--gray-2);
    height: 3.75rem;
    margin-right: 0.5rem;
    position: relative;
  }
  .FileCard-icon[data-type="pdf"] {
    color: var(--red);
  }
  .FileCard-icon[data-type*="doc"] {
    color: var(--blue);
  }
  .FileCard-icon[data-type*="ppt"],
  .FileCard-icon[data-type="key"] {
    color: var(--orange);
  }
  .FileCard-icon[data-type*="xls"] {
    color: var(--green);
  }
  .FileCard-icon[data-type="rar"],
  .FileCard-icon[data-type="zip"] {
    color: var(--brand-1);
  }
  .FileCard-icon .Icon {
    font-size: 3.75rem;
  }
  .FileCard-name {
    height: 2.375rem;
    line-height: 1.4;
    margin-bottom: 0.25rem;
  }
  .FileCard-ext {
    bottom: 0.9375rem;
    font-size: 1rem;
    font-weight: 700;
    left: 1.25rem;
    max-width: 3.125rem;
    position: absolute;
    text-transform: uppercase;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
  }
  .FileCard-meta {
    color: var(--gray-3);
    font-size: 0.75rem;
  }
  .FileCard-meta > a,
  .FileCard-meta > span {
    margin-right: 0.625rem;
  }
  .FileCard-meta a {
    color: var(--link-color);
    text-decoration: none;
  }
  .Form {
    background: var(--white);
  }
  .Form.is-light .FormItem {
    padding: 0;
  }
  .Form.is-light .HelpText,
  .Form.is-light .Label,
  .FormItem {
    padding: 0 var(--gutter);
  }
  .FormItem {
    position: relative;
  }
  .FormItem + .FormItem {
    margin-top: 1.25rem;
  }
  .FormItem.required .Label:after {
    color: var(--red);
    content: "*";
    display: inline-block;
    font-family: SimSun, sans-serif;
    font-size: 0.875rem;
    line-height: 1;
    vertical-align: middle;
  }
  .FormItem.is-invalid .HelpText,
  .FormItem.is-invalid .Label {
    color: var(--red);
  }
  .FormItem.is-invalid .Input {
    border-color: var(--red);
  }
  .FormItem .CheckboxGroup,
  .FormItem .RadioGroup {
    margin-top: 0.625rem;
  }
  .FormItem .Label + .Input {
    margin-top: 0.3125rem;
  }
  .FormActions {
    background: var(--white);
    display: flex;
    padding: 0.625rem var(--gutter);
  }
  .FormActions .Btn {
    flex: 1;
  }
  .FormActions .Btn + .Btn {
    margin-left: 0.375rem;
  }
  .MessageContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 0;
    position: relative;
  }
  .MessageContainer > .PullToRefresh {
    flex: 1;
  }
  .MessageContainer > .PullToRefresh > .PullToRefresh-inner {
    box-sizing: border-box;
    padding-bottom: 3.375rem;
  }
  .MessageContainer:focus {
    outline: 0;
  }
  .MessageList {
    font-size: 0.9375rem;
    padding: var(--gutter);
  }
  .RecorderToast {
    background: rgba(51, 51, 51, 0.87);
    border-radius: 12px;
    color: var(--white);
    height: 10rem;
    left: 50%;
    padding: 0.625rem;
    position: fixed;
    text-align: center;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 10rem;
    z-index: 100;
  }
  .Recorder--cancel .RecorderToast {
    color: var(--red);
  }
  .Recorder--cancel .Recorder-btn {
    background: rgba(0, 0, 0, 0.04);
    color: var(--gray-3);
  }
  .RecorderToast-icon {
    font-size: 2.3125rem;
    position: relative;
  }
  .RecorderToast-waves {
    height: 100%;
    position: absolute;
    transition: 0.3s;
    width: 100%;
    z-index: -1;
  }
  .RecorderToast-wave-1,
  .RecorderToast-wave-2,
  .RecorderToast-wave-3 {
    -webkit-animation: wave 10s linear infinite;
    animation: wave 10s linear infinite;
    color: var(--brand-2);
    position: absolute;
    z-index: -1;
  }
  .RecorderToast-wave-1 {
    font-size: 11rem;
    left: 0.875rem;
    opacity: 0.2;
    top: -1.5625rem;
  }
  .RecorderToast-wave-2 {
    font-size: 11.625rem;
    left: -1.3125rem;
    opacity: 0.4;
    top: -0.75rem;
  }
  .RecorderToast-wave-3 {
    font-size: 4.4375rem;
    left: 3.4375rem;
    opacity: 0.8;
    top: 2.5rem;
  }
  .Recorder-btn {
    -webkit-touch-callout: none;
    background: var(--white);
    border-radius: 20px;
    color: var(--gray-dark);
    height: 2.25rem;
    line-height: 2.25rem;
    text-align: center;
    transition: 0.3s;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  @-webkit-keyframes wave {
    0% {
      -webkit-transform: translateY(5%) rotate(0);
      transform: translateY(5%) rotate(0);
    }
    50% {
      -webkit-transform: translateY(-5%) rotate(180deg);
      transform: translateY(-5%) rotate(180deg);
    }
    to {
      -webkit-transform: translateY(5%) rotate(1turn);
      transform: translateY(5%) rotate(1turn);
    }
  }
  @keyframes wave {
    0% {
      -webkit-transform: translateY(5%) rotate(0);
      transform: translateY(5%) rotate(0);
    }
    50% {
      -webkit-transform: translateY(-5%) rotate(180deg);
      transform: translateY(-5%) rotate(180deg);
    }
    to {
      -webkit-transform: translateY(5%) rotate(1turn);
      transform: translateY(5%) rotate(1turn);
    }
  }
  .Composer {
    align-items: flex-end;
    display: flex;
    padding: 0.5625rem var(--gutter);
  }
  .Composer > div + div {
    margin-left: 0.5625rem;
  }
  .Composer-actions {
    align-items: center;
    display: flex;
    height: 2.25rem;
  }
  .Composer-actions .IconBtn {
    color: var(--gray-1);
    font-size: 1.875rem;
  }
  .Composer-toggleBtn {
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
  }
  .Composer-toggleBtn.active .Icon {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .Composer-inputWrap {
    flex: 1;
    position: relative;
  }
  .Composer-input {
    background: var(--white);
    border: 0;
    border-radius: 12px;
    caret-color: var(--brand-2);
    font-size: 0.9375rem;
    line-height: 1.25rem;
    max-height: 8.25rem;
    overflow: hidden;
    padding: 0.5rem 0.75rem;
    transition: border-color 0.15s ease-in-out;
  }
  .Composer-sendBtn {
    min-width: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .SendConfirm .Modal-dialog {
    margin: 1.25rem;
    width: 30rem;
  }
  .SendConfirm-inner {
    height: 20rem;
    text-align: center;
  }
  .SendConfirm-inner img {
    max-height: 100%;
    max-width: 100%;
  }
  html {
    height: 100vh;
  }
  html[data-safari] {
    height: calc(100vh - calc(100vh - 100%));
  }


  .ChatApp {
    -webkit-tap-highlight-color: transparent;
    background: var(--gray-6);
    color: var(--gray-1);
    display: flex;
    flex-direction: column;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    height: 100%;
    line-height: 1.5;
  }
  .S--focusing {
    --safe-bottom: 0;
  }
  @media (hover: none) {
    .S--focusing .MessageList {
      margin-top: 75vh;
    }
  }
  .ChatFooter {
    background: var(--gray-7);
    padding-bottom: var(--safe-bottom);
    position: relative;
    z-index: 10;
  }
 